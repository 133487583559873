

.login-center{
    text-align: center;
    background-color: #fff;
    width: fit-content;
    padding: 20px 40px 10px 40px !important;
    border-radius: 50px !important;
    margin: auto;
}
.ant-layout-content{
    height: initial;
}